import React, { useEffect, useState } from 'react';

// material-ui
import {
    Dialog,
    IconButton,
    Slide,
    SlideProps,
    Typography,
    DialogContent,
    DialogTitle,
    Tabs,
    Tab,
} from '@mui/material';

// assets
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { ISubject } from 'types/monitoring/ctms';
import CdwPatientInfo from './CdwPatientInfo';
import CdwExamInfo from './CdwExamInfo';
import { Box } from '@mui/system';
import CdwFormInfo from './CdwFormInfo';

// slide animation
const Transition = React.forwardRef((props: SlideProps, ref) => <Slide direction="up" ref={ref} {...props} />); 
type scrollType = 'body' | 'paper' | undefined; 
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
};


const CdwDataDialog = ({ subject, open, handleClose }: { subject: ISubject, open: boolean; handleClose: any}) => {
  const [scroll, setScroll] = useState<scrollType>('body');
  const [value, setValue] = React.useState(0);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ mt: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
      <div>
          <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} scroll={scroll} className="cdw-dialog">
              {open && (
                  <>
                      <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{fontSize: '1rem', padding: '5px 24px'}}>
                        <FormattedMessage id="monitoring-data" />
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent dividers={scroll === 'paper'} sx={{ mt: 3 }}>
                        <Typography variant="h4" color="inherit" mb={1}>
                          <FormattedMessage id="patient-info" />
                        </Typography>
                        <CdwPatientInfo subject={subject} />
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                          <Tabs value={value} 
                            onChange={handleChange} 
                            aria-label="patient-info-tabs"
                            textColor="secondary"
                            indicatorColor="secondary">
                            <Tab label="검사 정보" sx={{ typography: 'h4' }} {...a11yProps(0)} />
                            <Tab label="서식 정보" sx={{ typography: 'h4' }} {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          <CdwExamInfo subject={subject} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          <CdwFormInfo subject={subject} />
                        </CustomTabPanel>
                      </DialogContent>
                  </>
              )}
          </Dialog>
      </div>
  );
};

export default CdwDataDialog;
