import { CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { ISchedule } from 'types/admin/schedule';
import ScheduleList from 'ui-component/admin/ScheduleList';
import CurSchedule from 'ui-component/admin/CurSchedule';
import MainCard from 'ui-component/cards/MainCard';
import ScheduleStudyList from 'ui-component/admin/ScheduleStudyList';
import { useIntl } from 'react-intl';
import { sendGet } from 'utils/guard-axios';
import { API } from 'constant/api';
import TitleCard from 'ui-component/cards/TitleCard';
import { IconCalendarStats } from '@tabler/icons';

const ScheduleSettingView = () => {
  const [dataSource, setDataSource] = useState<ISchedule[]> ([]);
  const [curSchedule, setCurSchedule] = useState<ISchedule | null> (null);
  const [loading, setLoading] = useState<boolean> (false);

  const getSchedules = async () => {
    setLoading(true);
    const response = await sendGet(API.GET_SCHEDULE_LIST);
    setLoading(false);

    if (response?.data) {
      setDataSource(response.data);
    } else {
      setDataSource([])
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <Box>
      <TitleCard title="스케쥴 설정" IconElement={IconCalendarStats} />
      {loading && 
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      <Grid container spacing={3} mt={1}>
        <Grid item xs={5} xl={3}>
          <ScheduleList onSelected={setCurSchedule} data={dataSource} reloadData={getSchedules} /> 
        </Grid>
        { curSchedule && 
          <Grid item xs={7} xl={9}>
            <Box>
              <MainCard>
                <CurSchedule schedule={curSchedule} onSelected={setCurSchedule} reloadData={getSchedules} />
              </MainCard>
            </Box>
            <Box mt={'24px'}>
              <MainCard> 
                <ScheduleStudyList schedule={curSchedule} />
              </MainCard>
            </Box>
          </Grid>
        }
      </Grid>
    </Box>
)};

export default ScheduleSettingView;
