import { CircularProgress, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage, useIntl } from "react-intl";
import { ISchedule, IScheduleDetail } from "types/admin/schedule";
import { IconRefresh, IconClockPlay, IconClockStop } from '@tabler/icons';
import { useEffect, useState } from "react";
import DataGrid, {
  Scrolling,
  Column,
  FilterRow
} from 'devextreme-react/data-grid';
import { getCaptionText } from "utils/inil";
import { green, red } from "@mui/material/colors";
import { errorToast } from "utils/toast";
import { API } from "constant/api";
import { sendGet, sendPost } from "utils/guard-axios";
import axios from "axios";

let sseEventList: EventSource[] = [];

const ScheduleStudyList = ({ schedule }: { schedule: ISchedule }) => {
  const [loading, setLoading] = useState<boolean> (false);
  const [dataSource, setDataSource] = useState<IScheduleDetail[]> ([]);
  const [sseDataMap, setSseDataMap] = useState<Map<string, any>> (new Map<string, any> ());
  const intl = useIntl();

  useEffect(() => {
    getScheduleDetails();
  }, [ schedule ]);

  useEffect(() => {
    return () => clearSseEvent();
  }, []);

  const clearSseEvent = () => {
    for(let sseEvent of sseEventList) {
      sseEvent.close();
    }
    sseEventList = [];
  };

  const registerSseEvent = async (irbNo: string) => {
    const lastDataRes = await sendGet(`${API.SSE_LAST_DATA}/${irbNo}`);

    if (lastDataRes?.data) {
      sseDataMap.set(irbNo, lastDataRes.data);
      setSseDataMap(new Map(sseDataMap));
    } 

    const sseEvent = new EventSource(`${API.SSE}/${irbNo}`, {
      withCredentials: true,
    });

    sseEvent.addEventListener(irbNo, (event: any) => {
      const data = JSON.parse(event.data);
      if (data.percent === 100) {
        setTimeout(() => {
          sseDataMap.delete(irbNo);
          setSseDataMap(new Map(sseDataMap));
          getScheduleDetails();
        }, 3000);
      }
      sseDataMap.set(irbNo, data);
      setSseDataMap(new Map(sseDataMap));
    });
  };

  const getScheduleDetails = async () => {
    const response = await sendGet(`${API.GET_SCHEDULE_DETAIL}?scheduleId=${schedule.id}`);
    if (response?.data) {
      const data: IScheduleDetail[] = response.data;
      setDataSource(data);
      setTimeout(() => {
        clearSseEvent();
        for(let schedule of data) {
          if (!schedule.isRunning) continue;
          registerSseEvent(schedule.irbNo);
        }
      }, 1000);
    } else {
      setDataSource([]);
    }
  };

  const runAndStopSchedule = async (scheduleDtl: IScheduleDetail) => {
    const requestUrl = scheduleDtl.isRunning ? API.CANCEL_SCHEDULE_DETAIL : API.RUN_SCHEDULE_DETAIL;
    setLoading(true);
    const response = await sendPost(requestUrl, scheduleDtl);
    setTimeout(() => {
      getScheduleDetails();
      sseDataMap.delete(scheduleDtl.irbNo);
      setLoading(false);
    }, 1000);
  };

  const getRateText = (event: any) => {
    const irbNo: string = event.data.irbNo;
    const rate = sseDataMap.get(irbNo) ? sseDataMap.get(irbNo)?.percent : 0;
    return (
      <Box display={'flex'} justifyContent={'center'}>
        {event.data.isRunning && 
          <Box>
            {rate} %
          </Box>
        }
      </Box>
    )
  };

  const getStartStopCell = (event: any) => {
    return (
      <>
        {loading && 
           <Box display={'flex'} justifyContent={'center'}>
             <CircularProgress size={20}/>
           </Box>
        }
        {!loading && !event.data.isRunning && 
          <Box display={'flex'} justifyContent={'center'}>
            <IconButton className="p0" onClick={() => runAndStopSchedule(event.data)}>
              <IconClockPlay color={'#5c9b12'} />
            </IconButton>
          </Box>
        }
        {!loading && event.data.isRunning &&
          <Box display={'flex'} justifyContent={'center'}>
            <IconButton className="p0" onClick={() => runAndStopSchedule(event.data)}>
              <IconClockStop color={'#9e0505'} />
            </IconButton>
          </Box>
        }
      </>
    )
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h4">
          <FormattedMessage id="schedule-study-list" /> 
        </Typography>
        <IconButton aria-label="refresh" onClick={getScheduleDetails}>
          <IconRefresh />
        </IconButton>
      </Box>
      <Box mt={3}>
        <DataGrid dataSource={dataSource} 
          height={877} 
          showBorders={true} 
          showRowLines={true} 
          allowColumnResizing={true}
          columnResizingMode={'widget'}>
          <FilterRow visible={true} />
          <Column allowFiltering={true} width={50} dataField="seq" caption={getCaptionText(intl, 'seq')} />
          <Column allowFiltering={true} width={50} dataField="id" caption={getCaptionText(intl, 'schedule-detail-id')} />
          <Column allowFiltering={true} width={150} dataField="irbNo" caption={getCaptionText(intl, 'irb-no')} />
          <Column allowFiltering={true} minWidth={150} dataField="title" caption={getCaptionText(intl, 'study-title')} />
          <Column allowFiltering={true} width={100} dataField="type" caption={getCaptionText(intl, 'study-type')} />
          <Column allowFiltering={true} width={100} dataField="lastStatus" caption={getCaptionText(intl, 'status')} />
          <Column allowFiltering={true} width={100} dataField="isActive" caption={getCaptionText(intl, 'is-active')} />
          <Column allowFiltering={false} width={80} caption={getCaptionText(intl, 'start-stop')} cellRender={getStartStopCell} />
          <Column allowFiltering={false} width={100} caption={getCaptionText(intl, 'rate')} cellRender={getRateText} alignment={'center'} />
          <Scrolling mode="virtual" />
        </DataGrid>
      </Box>
    </Box>
  )
};

export default ScheduleStudyList;
