import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { API } from "constant/api";
import { useEffect, useState } from "react";
import { IDesign, ISubject } from "types/monitoring/ctms";
import axios from "axios";
import { errorToast } from "utils/toast";
import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
  Selection
} from 'devextreme-react/data-grid';
import { getCaptionText } from "utils/inil";
import { useIntl } from "react-intl";
import CdwDataDialog from "../cdw/CdwDataDialog";
import { sendGet } from "utils/guard-axios";

const DesignSubjectList = ({ design }: { design: IDesign }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean> (true);
  const [dataSource, setDataSource] = useState<ISubject[]> ([]);
  const [open, setOpen] = useState<boolean> (false);
  const [curSubject, setCurSubject] = useState<ISubject | null> (null);

  const getSubjectList = async () => {
    setLoading(true);
    const response = await sendGet(`${API.GET_SUBJECT_LIST}?designId=${design.designId}`);
    if (response?.data) {
      setDataSource(response.data);
    } else {
      setDataSource([]);
    }
    setLoading(false);
  };

  const onRowClick = (event: any) => {
    setCurSubject(event.data);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setCurSubject(null);
  };

  useEffect(() => {
    getSubjectList();
  }, [ design ]);

  return (
    <Box>
      {loading && 
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading && 
        <Box mt={3}>
          <DataGrid dataSource={dataSource} 
            height={695} 
            showBorders={true} 
            showRowLines={true} 
            onRowClick={onRowClick}
            allowColumnResizing={true}
            columnResizingMode={'widget'}>
            <Selection mode="single" />
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            <Column allowFiltering={true} width={150} dataField="ptNo" caption={getCaptionText(intl, 'pt-no')} />
            <Column allowFiltering={true} minWidth={150} dataField="name" caption={getCaptionText(intl, 'pt-nm')} />
            <Column allowFiltering={true} width={150} dataField="enrollmentId" caption={getCaptionText(intl, 'enrollment-id')} />
            <Column allowFiltering={true} width={150} dataField="screeningId" caption={getCaptionText(intl, 'screening-id')} />
            <Column allowFiltering={true} width={100} dataField="status" caption={getCaptionText(intl, 'status')} />
            <Column allowFiltering={true} width={150} dataField="minDate" caption={getCaptionText(intl, 'study-start-date')} />
            <Column allowFiltering={true} width={150} dataField="maxDate" caption={getCaptionText(intl, 'study-end-date')} />
          </DataGrid>
          {!!curSubject && 
            <CdwDataDialog open={open} subject={curSubject} handleClose={() => closeDialog()} />
          }
        </Box>
      }
    </Box>
  )
};

export default DesignSubjectList;