import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/slices/user';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { IDPWContextType, InitialLoginContextProps } from 'types/auth';
import { Chance } from 'chance';
import { IUser } from 'types/login/login';
import { errorToast } from 'utils/toast';
import { API } from 'constant/api';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { signIn, signOut } from 'store/slices/user';

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| IDPW CONTEXT & PROVIDER ||============================== //
const IDPWContext = createContext<IDPWContextType | null>(null);

export const IDPWProvider = ({ children }: { children: React.ReactElement }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.user);

    useEffect(() => {
        const init = async () => {
            try {
                try {
                    const res = await axios.get(API.GET_LOGIN_USER);
                    const loginUser: IUser = res.data;
                    dispatch(signIn({
                        isLoggedIn: true,
                        user: loginUser
                    }));
                } catch(err) {
                    dispatch(signOut());
                }
            } catch (err) {
                console.error(err);
                dispatch(signOut());
            }
        };
        init();
    }, []);

    const login = async (loginId: string, password: string) => {
        //TODO: 로그인 API 호출
        try {
            const loginType = 'ID_PW';
            const res = await axios.post(API.SIGN_IN, { loginId, password, loginType });
            const loginUser: IUser = res.data;
            dispatch(signIn({
                isLoggedIn: true,
                user: loginUser
            }));
        } catch(err: any) {
            if (err?.message) {
                errorToast(err.message);

            }
        }
    };

    const logout = async () => {
        try {
            const res = await axios.post(API.SIGN_OUT, {});
            dispatch(signOut());
        } catch(err) {
            console.error(err);
        }
    };

    const register = async (loginId: string, password: string, firstName: string, lastName: string) => {};
    const resetPassword = (loginId: string) => console.log(loginId);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <IDPWContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</IDPWContext.Provider>
    );
};

export default IDPWContext;
