import { Button, FormControlLabel, Input, Radio, RadioGroup, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { API } from "constant/api";
import { setHours } from "date-fns";
import { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { ISchedule, IScheduleConst, repeatPeriodTextList, weekTextList } from "types/admin/schedule";
import { IStudySchedule } from "types/monitoring/ctms";
import { getCaptionText } from "utils/inil";



const ScheduleAlertBody = ({ intl, schedule }: { intl: IntlShape; schedule: ISchedule }) => {
  const [curWeekTextId, setCurWeekTextId] = useState<number> (0);
  const [curPeriodId, setPeriodId] = useState<number> (2);
  const [curDay, setCurDay] = useState<string> ('1');
  const [curHour, setCurHour] = useState<string> ('0');
  const [curMin, setCurMin] = useState<string> ('0');
  const [curName, setCurName] = useState<string> ('');
  const [isActive, setIsActive] = useState<boolean> (false);

  const initialize = () => {
    if (!schedule.id) return;
    setCurName(schedule.name);
    setIsActive(schedule.isActive);

    const ruleArr = schedule.rule.split(' ');
    if (ruleArr[3] !== '*') {
      setPeriodId(1);
      setCurDay(ruleArr[3]);
    } else if (ruleArr[5] !== '*') {
      setPeriodId(2);
      setCurWeekTextId(Number(ruleArr[5]));
    } else {
      setPeriodId(3);
    }

    setCurHour(ruleArr[2]);
    setCurMin(ruleArr[1]);
  };

  const changeSchedule = () => {
    schedule.isActive = isActive;
    schedule.name = curName;
    schedule.rule = getCronRule();
  };

  const getCronRule = (): string => {
    let cronRule = [ '*', '*', '*', '*', '*', '*' ];

    if (curPeriodId === 1) {
      cronRule[3] = curDay;
      cronRule[4] = '*'
      cronRule[5] = '*'
    }

    if (curPeriodId === 2) {
      cronRule[3] = '*';
      cronRule[4] = '*';
      cronRule[5] = String(curWeekTextId);
    }

    if (curPeriodId === 3) {
      cronRule[3] = '*';      
      cronRule[4] = '*'
      cronRule[5] = '*'
    }

    if (Number(curHour) >= 0 && Number(curHour) <= 23) {
      cronRule[2] = curHour;
    } else {
      cronRule[2] = '0';
    }

    if (Number(curMin) >= 0 && Number(curMin) <= 59) {
      cronRule[1] = curMin;
    } else {
      cronRule[1] = '0';
    }

    return cronRule.join(' ');
  };

  useEffect(() => {
    if (!schedule) return;
    initialize();
  }, [ schedule ]);

  useEffect(() => {
    changeSchedule();
  }, [ curWeekTextId, curPeriodId, curDay, curHour, curMin, curName, isActive ]);

  return (
    <Box>
      <TextField value={curName} 
        fullWidth={true} 
        variant="outlined" 
        label="스케쥴 명" 
        style={{'marginTop': '15px'}}
        onChange={(event: any) => setCurName(event.target.value)} />
      <RadioGroup 
        aria-label="gender" 
        name="controlled-radio-buttons-group"
        style={{'marginTop': '15px', 'flexDirection': 'row'}}
        value={curPeriodId}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>  setPeriodId(Number(value))}>
        {repeatPeriodTextList.map((periodText: IScheduleConst, idx: number) => {
          return (
            <FormControlLabel key={idx} value={periodText.id} control={<Radio />} label={periodText.text} />
          )
        })}
      </RadioGroup>
      {curPeriodId === 1 && 
        <Box display={'flex'} alignItems={'center'} mt={2}>
          <TextField value={curDay} 
            size="small" 
            style={{'width': '100px', 'marginRight': '5px'}} 
            type={'number'}
            onChange={(event: any) => setCurDay(event.target.value)}  /> 일
        </Box>
      }
      {curPeriodId === 2 && 
        <Box mt={2} display={'flex'} justifyContent={'space-between'}>
          {weekTextList.map((weekText: IScheduleConst, idx: number) => {
            return (
              <Button key={idx} 
                onClick={() => setCurWeekTextId(weekText.id)}
                variant={weekText.id === curWeekTextId ? "contained" : "outlined"}
                style={{'width': '5px', 'margin': '0 3px', 'minWidth': '50px'}}>
                {weekText.text}
              </Button>
            )
          })}
        </Box>
      }
      <Box mt={3} display={'flex'} alignItems={'center'}>
        <TextField value={curHour} 
          size="small" 
          style={{'width': '70px', 'marginRight': '5px'}} 
          type={'number'}
          onChange={(event: any) => setCurHour(event.target.value)} /> 시
        <TextField value={curMin}  
          size="small" 
          style={{'width': '70px', 'marginLeft': '15px', 'marginRight': '5px'}} 
          type={'number'}
          onChange={(event: any) => setCurMin(event.target.value)} /> 분에 스케쥴을 실행합니다.
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={3}>
        <h4>
          {getCaptionText(intl, 'is-active')}
        </h4>
        <Switch
          size="medium"
          checked={isActive}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsActive(event.target.checked)}
          inputProps={{ 'aria-label': 'controlled-direction' }} />
      </Box>
    </Box>
  )
};

export default ScheduleAlertBody;