import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { IError, IErrorDtl } from "types/admin/error";
import MainCard from "ui-component/cards/MainCard";
import { getCaptionText } from "utils/inil";
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { useEffect, useState } from "react";
import { errorToast } from "utils/toast";
import axios from "axios";
import { API } from "constant/api";
import { Box } from "@mui/system";
import DataGrid, {
  Scrolling,
  Column,
  FilterRow
} from 'devextreme-react/data-grid';
import { sendGet } from "utils/guard-axios";

const CurError = ({ error }: { error: IError }) => {
  const intl = useIntl();
  const [dataSource, setDataSource] = useState<IErrorDtl[]> ([]);

  useEffect(() => {
    getErrorDetails();
  }, [ error ]);

  const getErrorDetails = async () => {
    const response = await sendGet(`${API.GET_ERROR_DETAIL_LIST}?errId=${error.id}`);
    if (response?.data) {
      setDataSource(response.data);
    } else {
      setDataSource([])
    }
  };

  return (
    <MainCard title={getCaptionText(intl, 'err-detail')}>
      <Grid item xl={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={2} xl={2}>
            <InputLabel horizontal><FormattedMessage id="err-type" /></InputLabel>
          </Grid>
          <Grid item lg={10} xl={10}>
            <TextField value={error.errType || ''} fullWidth size="small" inputProps={{ readOnly: true, }}/>
          </Grid>
          <Grid item lg={2} xl={2}>
            <InputLabel horizontal><FormattedMessage id="err-msg" /></InputLabel>
          </Grid>
          <Grid item lg={10} xl={10}>
            <TextField value={error.errMsg || ''} fullWidth size="small" inputProps={{ readOnly: true, }} multiline rows={20}/>
          </Grid>
          <Grid item xl={12}>
            <Box mt={1}>
              <DataGrid dataSource={dataSource} 
                height={450} 
                showBorders={true} 
                showRowLines={true} 
                allowColumnResizing={true}
                columnResizingMode={'widget'}>
                <Column allowFiltering={true} minWidth={300} dataField="clsNm" caption='CLASS' />
                <Column allowFiltering={true} width={300} dataField="mtdNm" caption='METHOD' />
                <Column allowFiltering={true} width={200} dataField="line" caption='LINE' alignment="left" /> 
                <Scrolling mode="virtual" />
              </DataGrid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      
    </MainCard>
  );
};

export default CurError;