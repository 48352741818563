import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ScheduleLogView from 'views/admin/ScheduleLogView';
import ScheduleSettingView from 'views/admin/ScheduleSettingView';
import ErrorLogView from 'views/admin/ErrorLogView';

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/admin/schedule',
            element: <ScheduleSettingView />
        },
        {
            path: '/admin/schedule-log',
            element: <ScheduleLogView />
        },
        {
            path: '/admin/error-log',
            element: <ErrorLogView />
        }
    ]
};

export default AdminRoutes;
