import { Typography } from '@mui/material';
import { grey, red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { ISchedule } from 'types/admin/schedule';
import { IconCircleCheck, IconCircleMinus, IconEdit, IconEditCircle, IconTrash } from '@tabler/icons';
import ScheduleActiveBox from './ScheduleActiveBox';
import { convertRuleIntoText } from 'utils/schedule';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getCaptionText } from 'utils/inil';
import ScheduleAlertBody from './ScheduleAlertBody';
import { successToast } from 'utils/toast';
import { sendDelete, sendPut } from 'utils/guard-axios';
import { API } from 'constant/api';
import { useEffect, useState } from 'react';

const CurSchedule = ({ schedule, reloadData, onSelected }: { schedule: ISchedule; reloadData: Function; onSelected: Function }) => {
  const [dataSource, setDataSource] = useState<ISchedule> (schedule);
  const intl = useIntl();

  const editSchedule = async () => {
    try {
      const clone = JSON.parse(JSON.stringify(schedule));
      const modalRes = await openEditScheduleModal(clone);
      if (!modalRes.isConfirmed) return;

      const res = await sendPut(`${API.UPDATE_SCHEDULE}/${schedule.id}`, clone);
      successToast('저장되었습니다.');
      setDataSource(clone);
      reloadData();
    } catch(err) {
      console.error(err);
    }
  };

  const deleteSchedule = async () => {
    try {
      const modalres = await openDeleteScheduleModal();
      if (!modalres.isConfirmed) return;

      const res = await sendDelete(`${API.DELETE_SCHEDULE}/${schedule.id}`);
      successToast('삭제되었습니다.');
      onSelected(null);
      reloadData();
    } catch(err) {
      console.error(err);
    }
  };

  const openDeleteScheduleModal = () => {
    const MySwal = withReactContent(Swal);
    
    return MySwal.fire({
      title: `<h5>해당 스케쥴을 정말 삭제하시겠어요?</h5>`,
      confirmButtonText: getCaptionText(intl, 'save'),
      showCancelButton: true,
      cancelButtonText: getCaptionText(intl, 'cancel'),
      icon: 'warning'
    });
  };

  const openEditScheduleModal = (schedule: ISchedule) => {
    const MySwal = withReactContent(Swal);
    
    return MySwal.fire({
      title: `<h5>스케쥴 수정</h5>`,
      confirmButtonText: getCaptionText(intl, 'save'),
      showCancelButton: true,
      cancelButtonText: getCaptionText(intl, 'cancel'),
      html: <ScheduleAlertBody intl={intl} schedule={schedule} />,
      preConfirm: () => {
        if (!schedule.name) {
          alert('스케쥴 명을 입력해주세요.');
          return false;
        }
        return true;
      }
    });
  };

  useEffect(() => {
    setDataSource(schedule);
  }, [ schedule ]);

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant='h5' color={grey[700]}>
          ID :
        </Typography>
        <Typography ml={1} variant='h4'>
          {dataSource.id}
        </Typography>
        <Typography variant='h5' ml={5} color={grey[700]}>
          <FormattedMessage id="schedule-name" /> : 
        </Typography>
        <Typography ml={1} variant='h4'>
          {dataSource.name}
        </Typography>
        <Typography variant='h5' ml={5} color={grey[700]}>
          <FormattedMessage id="schedule-rule" /> : 
        </Typography>
        <Typography ml={1} variant='h4'>
          {convertRuleIntoText(dataSource.rule)}
        </Typography>
        <Typography variant='h5' ml={5} color={grey[700]}>
          <FormattedMessage id="is-active" /> : 
        </Typography>
        <ScheduleActiveBox isActive={dataSource.isActive} />
      </Box>
      <Box display={'flex'}>
        <IconTrash className={'pointer'} 
          color={grey[500]} 
          onClick={deleteSchedule} />
        <Box ml={1}>
          <IconEdit className={'pointer'} 
            color={grey[500]}
            onClick={editSchedule} />
        </Box>
      </Box>
    </Box>
  );
};

export default CurSchedule;
