// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import menuReducer from './slices/menu';
import snackBarReducer from './slices/snackbar';
import userReducer from './slices/user';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    menu: persistReducer(
        {
            key: 'menu',
            storage,
            keyPrefix: 'berry-'
        },
        menuReducer
    ),
    user: userReducer,
    snackbar: snackBarReducer
});

export default reducer;
