import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { API } from 'constant/api';
import { LoadPanel } from 'devextreme-react/load-panel';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { LOGIN, LOGOUT } from 'store/actions';
import { IUser } from 'types/login/login';
import { errorToast } from 'utils/toast';
import { InitialLoginContextProps } from 'types/auth';
import { Chance } from 'chance';
import { signIn, signOut } from 'store/slices/user';
import { useDispatch, useSelector } from 'store';

const SsoView = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = searchParams.get('id');
  const integrity = searchParams.get('integrity');

  const checkSso = async () => {
    try {
      if (!id || !integrity) throw new Error('파라미터가 잘못되었습니다.');
      const res = await axios.post(API.SSO, { ctmsId: id, integrity });
      // console.log("###### ", res);
      const loginUser: IUser = res.data;
      dispatch(signIn({
        isLoggedIn: true,
        isSso: true,
        user: loginUser
      }));
      navigate('/monitoring/study');
    } catch(err) {
      errorToast('인증에 실패하였습니다.');
      console.error(err);
      navigate('/login');
    }
  };

  useEffect(() => {
    checkSso();
  }, []);

  return (
    <Box className='sso-loader'>
      <Box style={{'textAlign': 'center'}}>
        <BeatLoader color="#36d7b7" size={30} />
        <Typography variant={'h2'} mt={4} align='center'>
          Loading...
        </Typography>
      </Box>
    </Box>
)};

export default SsoView;
