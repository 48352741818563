import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  customClass: 'toast-top',
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

const errorToast = (context: string) => {
  Toast.fire({
    icon: "error",
    title: context,
  });
};

const successToast = (context: string) => {
  Toast.fire({
    icon: "success",
    title: context
  });
};

export { errorToast, successToast }