// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconTable, IconCalendarStats, IconBug } from '@tabler/icons';

// type
import { NavItemType } from 'types';

const icons = {
    IconTable: IconTable,
    IconCalendarStats: IconCalendarStats,
    IconBug: IconBug,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const admin: NavItemType = {
    id: 'admin',
    title: <FormattedMessage id="admin-setting" />,
    icon: icons.IconTable,
    type: 'group',
    children: [
        {
            id: 'schedule-setting',
            title: <FormattedMessage id="schedule-setting" />,
            type: 'item',
            url: '/admin/schedule',
            icon: icons.IconCalendarStats,
            breadcrumbs: false
        },
        {
            id: 'schedule-log',
            title: <FormattedMessage id="schedule-log" />,
            type: 'item',
            url: '/admin/schedule-log',
            icon: icons.IconTable,
            breadcrumbs: false
        },
        {
            id: 'error-log',
            title: <FormattedMessage id="error-log" />,
            type: 'item',
            url: '/admin/error-log',
            icon: icons.IconBug,
            breadcrumbs: false
        }        
    ]
};

export default admin;
