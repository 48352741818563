import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// rHelpRoutes
import LoginRoutes from './LoginRoutes';
import AdminRoutes from './AdminRoutes';
import MonitorRoutes from './MonitorRoutes';
import SsoRoutes from './SsoRoutes';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <AuthGuard><MainLayout /></AuthGuard> },
        MonitorRoutes,
        LoginRoutes, 
        SsoRoutes,
        AdminRoutes ]);
}
