import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import StudyListView from 'views/monitoring/StudyListView';
import StudyDetailView from 'views/monitoring/StudyDetailView';
import AuthGuard from 'utils/route-guard/AuthGuard';

const MonitorRoutes = {
    path: '/',
    element: (
       <AuthGuard>
            <MainLayout />
       </AuthGuard>
    ),
    children: [
        {
            path: 'monitoring/study',
            element: <StudyListView />
        },
        {
            path: 'monitoring/study/:irbNo',
            element: <StudyDetailView />
            
        }    
    ]
};

export default MonitorRoutes;
