import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { IconCircleCaretRight, IconList, TablerIcon } from "@tabler/icons";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface ITitleCardParam {
  title: string;
  IconElement?: TablerIcon; 
  historyBackUrl?: string;
}

const TitleCard = ({ title, IconElement, historyBackUrl }: ITitleCardParam) => {
  const navigate = useNavigate();
  const historyBack = useCallback(() => {
    if (!historyBackUrl) return;

    navigate(historyBackUrl);
  }, [ historyBackUrl ]);

  return (
    <Card className="title-card">
      <CardContent>
        <Box display={'flex'} alignItems={'center'}>
          {IconElement && 
            <IconElement />
          }
          <span className="title">
            {title}
          </span>
        </Box>
        { historyBackUrl && 
          <IconList onClick={historyBack} cursor={'pointer'} />
        }
      </CardContent>
    </Card>
  )
};

export default TitleCard;
