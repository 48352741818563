import { IDesign } from "types/monitoring/ctms";
import SubCard from "ui-component/cards/SubCard";
import List from 'devextreme-react/list';
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { grey } from "@mui/material/colors";

const DesignList = ({ designList, onSelected }: { designList: IDesign[], onSelected: Function }) => {
  const onItemClick = (item: any) => {
    onSelected(item.itemData);
  };

  const designItem = (item: IDesign) => {
    return (
      <Box pt={1} pb={1}>
        <Typography variant="h5" p={0} m={0} className={'text-muted'}>
          {item.designId}
        </Typography>
        <Typography variant="h4" p={0} m={0}>
          {item.name}
        </Typography>
      </Box>    
    )
  };

  return (
    <Box>
      {designList.length === 0 &&
        <Typography color={grey[700]} display={'flex'} justifyContent={'center'} className={'h740'}>
          <FormattedMessage id="not-exist-data" />
        </Typography>
      }
      {designList.length > 0 &&
        <List
          dataSource={designList}
          className={'h740'}
          itemRender={designItem}
          onItemClick={onItemClick}
        >
        </List>
      }
    </Box>
  );
};

export default DesignList;