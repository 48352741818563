import { CircularProgress, Typography } from "@mui/material";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import MainCard from "ui-component/cards/MainCard"
import List from 'devextreme-react/list';
import { Box } from "@mui/system";
import { IconCalendarStats, IconCalendarOff, IconSquarePlus } from '@tabler/icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { ISchedule } from "types/admin/schedule";
import { errorToast, successToast } from "utils/toast";
import { API } from "constant/api";
import { sendGet, sendPost } from "utils/guard-axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getCaptionText } from "utils/inil";
import ScheduleAlertBody from "./ScheduleAlertBody";

const ScheduleList = ({ onSelected, reloadData, data }: { onSelected: Function; reloadData: Function, data: ISchedule[] } ) => {
  const intl = useIntl();

  const onItemClick = (item: any) => {
    onSelected(item.itemData);
  };

  const scheduleItem = (item: ISchedule) => {
    return (
      <Box pt={1} pb={1} display={'flex'} justifyContent={'space-between'}>
        {item.name}
        {item.isActive ? <IconCalendarStats /> : <IconCalendarOff />}
      </Box>
    )
  };

  const createSchedule = async () => {
    try {
      const schedule: ISchedule = {
        id: 0,
        rule: '',
        name: '',
        isActive: false
      };
      const modalRes = await openCreateScheduleModal(schedule);
      if (!modalRes.isConfirmed) return;

      const res = await sendPost(API.CREATE_SCHEDULE, schedule);
      successToast('저장되었습니다.');
      reloadData();
    } catch(err) {
      console.error(err);
    }
  };

  const openCreateScheduleModal = (schedule: ISchedule) => {
    const MySwal = withReactContent(Swal);
    
    return MySwal.fire({
      title: `<h5>스케쥴 추가</h5>`,
      confirmButtonText: getCaptionText(intl, 'save'),
      showCancelButton: true,
      cancelButtonText: getCaptionText(intl, 'cancel'),
      html: <ScheduleAlertBody intl={intl} schedule={schedule} />,
      preConfirm: () => {
        if (!schedule.name) {
          alert('스케쥴 명을 입력해주세요.');
          return false;
        }
        return true;
      }
    });
  };

  return (
    <MainCard>
      <Typography variant="h4">
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <FormattedMessage id="schedule-list" /> 
          <IconSquarePlus className="pointer" 
            color="#673ab7" 
            size={30} 
            onClick={createSchedule} />
        </Box>
        <Box mt={3} height={990}>
          <List
            dataSource={data}
            height={990}
            itemRender={scheduleItem}
            onItemClick={onItemClick}
            >
          </List>
        </Box>
      </Typography>
    </MainCard>
  )
};

export default ScheduleList;
