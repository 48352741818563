import { FormControlLabel, Radio, RadioGroup, Switch } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { API } from "constant/api";
import { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { ISchedule } from "types/admin/schedule";
import { IStudySchedule } from "types/monitoring/ctms";
import { getCaptionText } from "utils/inil";
import { convertRuleIntoText } from "utils/schedule";

const StudyScheduleAlertBody = ({ scheduleList, intl, values }: { scheduleList: ISchedule[], intl: IntlShape, values: IStudySchedule }) => {
  const [isActive, setIsActive] = useState<boolean> (!!values.isActive);
  const [scheduleId, setScheduleId] = useState<string> (String(values.scheduleId));

  return (
    <Box>
      <RadioGroup aria-label="gender" name="controlled-radio-buttons-group"
        value={scheduleId}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => { 
          setScheduleId(value);
          values.scheduleId = Number(value);
        }}>
        {scheduleList.map((schedule: ISchedule, idx: number) => {
          return (
            <FormControlLabel key={idx} value={schedule.id} control={<Radio />} label={`${schedule.name} (${convertRuleIntoText(schedule.rule)})`} />
          )
        })}
        <FormControlLabel value="0" control={<Radio />} label={getCaptionText(intl, 'schedule-not-set')} />
      </RadioGroup>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={3}>
        <h4>
          {getCaptionText(intl, 'is-active')}
        </h4>
        <Switch
          size="medium"
          checked={isActive}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.checked;
            setIsActive(value);
            values.isActive = value;
          }}
          inputProps={{ 'aria-label': 'controlled-direction' }} />
      </Box>
    </Box>
  )
};

export default StudyScheduleAlertBody;