import { Chip, FormControlLabel, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { IconCalendarStats, IconSettings } from "@tabler/icons";
import { FormattedMessage, useIntl } from "react-intl";
import MainCard from "ui-component/cards/MainCard";
import { errorToast, successToast } from "utils/toast";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getCaptionText } from "utils/inil";
import { IStudy, IStudySchedule } from "types/monitoring/ctms";
import moment from "moment";
import { red } from "@mui/material/colors";
import ScheduleActiveBox from "ui-component/admin/ScheduleActiveBox";
import StudyScheduleAlertBody from "./StudyScheduleAlertBody";
import axios from "axios";
import { ISchedule } from "types/admin/schedule";
import { API } from "constant/api";
import { sendGet, sendPut } from "utils/guard-axios";
import { LinearProgressWithLabel } from "ui-component/extended/LinearProgressWithLabel";
import { useEffect, useState } from "react";
import { convertRuleIntoText } from "utils/schedule";

let sseEvent: EventSource | null = null;
const StudySchedule = ({ study, onUpdated, onRefresh }: { study: IStudy, onUpdated: Function; onRefresh: Function }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [percent, setPercent] = useState<number> (0);

  useEffect(() => {
    if (study.isRunning) {
      registerSseEvent(study.irbNo);
    }
    return () => sseEvent?.close();
  }, [ study ]);

  const registerSseEvent = async (irbNo: string) => {
    const lastDataRes = await sendGet(`${API.SSE_LAST_DATA}/${irbNo}`);

    if (lastDataRes?.data) {
      setPercent(lastDataRes.data.percent);
    } 

    sseEvent = new EventSource(`${API.SSE}/${irbNo}`, {
      withCredentials: true,
    });

    sseEvent.addEventListener(irbNo, (event: any) => {
      const data = JSON.parse(event.data);
      setPercent(data.percent || 0);
      if (data.percent === 100) {
        setTimeout(() => {
          onRefresh();
        }, 1000);
      }
    });
  };

  const editSchedule = async () => {
      const res = await sendGet(API.GET_SCHEDULE_LIST);
      const values: IStudySchedule = { isActive: study.isActive, scheduleId: study.scheduleId };
      if (res) {
        const swalRes = await openEditScheduleModal(res.data, values);
        if (!swalRes.isConfirmed) {
          return;
        }
      }

      study.isActive = values.isActive;
      study.scheduleId = values.scheduleId;

      const updateRes = await sendPut(API.UPDATE_STUDY_SCHEDULE, study);
      if (updateRes) {
        onUpdated(updateRes.data);
        successToast(getCaptionText(intl, 'save-success'));
      }
      
  };

  const getTitle = () => {
    return (
      <Typography variant={'h4'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'} >
          <IconCalendarStats />
          <Box ml={1} mt={'3px'}>
            <FormattedMessage id="schedule-setting" />
          </Box>
        </Box>
        <Chip
                sx={{
                    height: '32px',
                    alignItems: 'center',
                    borderRadius: '18px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                label={<IconSettings stroke={1.5} size="25px" color={theme.palette.primary.main} />}
                variant="outlined"
                aria-haspopup="true"
                onClick={editSchedule}
                color="primary"
                aria-label="user-account"
            />
      </Typography>
    );
  };

  const openEditScheduleModal = (scheduleList: ISchedule[], values: IStudySchedule) => {
    const MySwal = withReactContent(Swal);

    return MySwal.fire({
      title: `<h5>${getCaptionText(intl, 'schedule-setting')}</h5>`,
      confirmButtonText: getCaptionText(intl, 'save'),
      showCancelButton: true,
      cancelButtonText: getCaptionText(intl, 'cancel'),
      html: <StudyScheduleAlertBody intl={intl} scheduleList={scheduleList} values={values} /> 
    });
  };

  return (
    <Box className="study-schedule">
      <MainCard title={getTitle()} >
        {!!study.scheduleId &&
          <>
            <Box display={'flex'} alignItems={'center'}>
              <FormattedMessage id="data-load-rule" />:
              <Typography variant="h5" ml={1}>
                {convertRuleIntoText(study.scheduleRule || '')}
              </Typography>
            </Box>
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <FormattedMessage id="is-active" />: 
              <ScheduleActiveBox isActive={study.isActive} />
            </Box >
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <FormattedMessage id="last-load-date" />:
              <Typography variant="h5" ml={1}>
                {study.lastLoadDtm ? moment(study.lastLoadDtm).format('YYYY-MM-DD hh:mm:ss') : '-'}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="h5">
                데이터 적재 진행률
              </Typography>
              <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={study.isRunning ? percent : 100} />
              </Box>
            </Box>
          </>
        }
        {!study.scheduleId &&
          <Typography variant="h5" color={red[800]} mt={2}>
            <FormattedMessage id="not-exist-schedule-msg" />
          </Typography>
        }
      </MainCard>
    </Box>
  );
};

export default StudySchedule;