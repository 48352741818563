import { useTheme } from '@mui/material/styles';
import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IStudy } from "types/monitoring/ctms"
import MainCard from "ui-component/cards/MainCard"
import { FormattedMessage } from 'react-intl';

const StudyInfoCard = ({ study, onClick, className }: { study: IStudy; onClick?: Function; className?: string }) => {
  const theme = useTheme();

  const clickCard = () => {
    if (!onClick) return;
    onClick(study);
  }

  const getStudyTitle = (data: IStudy): React.ReactNode => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Chip
          size="small"
          label={data.type}
          style={{fontWeight: 'bold', fontSize: '12px'}}
          sx={{
            color: theme.palette.background.default,
            bgcolor: theme.palette.info.dark
          }} />
          <Typography variant="h4" ml={2} display={'flex'} alignItems={'center'}>
            <Box className="text-muted"><FormattedMessage id="study-number" /> :</Box>
            <Box ml={1}>{data.irbNo}</Box>
          </Typography>
      </Box>
    )
  };

  return (
    <MainCard title={getStudyTitle(study)} className={className} onClick={clickCard}>
      {study.title}
    </MainCard>
  )
};

export default StudyInfoCard;