import axios, { AxiosResponse } from "axios";
import { errorToast } from "./toast";

const handleError = (error: any) => {
  if (error?.response?.status === 403) {
    errorToast('로그인 하셔야 이용가능합니다.');
    setTimeout(() => {
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }, 200);
  } else {
    errorToast('에러가 발생했습니다. \n관리자에게 문의 바랍니다.');
  }
};

export const sendGet = async (url: string) => {
  try {
    return await axios.get(url);
  } catch(error: any) {
    handleError(error);
    
  }
};

export const sendPost = async (url: string, data: any) => {
  try {
    return await axios.post(url, data);
  } catch(error: any) {
    handleError(error);
    
  }

};

export const sendPut = async (url: string, data: any) => {
  try {
    return await axios.put(url, data);
  } catch(error: any) {
    handleError(error);
  }
};

export const sendDelete = async (url: string) => {
  try {
    return await axios.delete(url);
  } catch(error: any) {
    handleError(error);
  }
}