import test from './test';
import admin from './admin';
import monitor from './monitoring';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [admin, monitor]
};

export default menuItems;
