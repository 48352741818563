import { CircularProgress, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconDeviceAnalytics } from '@tabler/icons';
import axios from 'axios';
import { API } from 'constant/api';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { IDesign, IStudy } from 'types/monitoring/ctms';
import SubCard from 'ui-component/cards/SubCard';
import TitleCard from 'ui-component/cards/TitleCard';
import DesignList from 'ui-component/monitor/design/DesignList';
import DesignSubjectList from 'ui-component/monitor/design/DesignSubjectList';
import StudyInfoCard from 'ui-component/monitor/study/StudyInfoCard';
import StudySchedule from 'ui-component/monitor/study/StudySchedule';
import { sendGet } from 'utils/guard-axios';
import { getCaptionText } from 'utils/inil';

const StudyDetailView = () => {
  const intl = useIntl();
  const { irbNo } = useParams();
  const [loading, setLoading] = useState<boolean> (false);
  const [study, setStudy] = useState<IStudy | null> (null);
  const [curDesign, setCurDesign] = useState<IDesign | null> (null);


  useEffect(() => {
    if (!irbNo) return;
    getStudy();
  }, [ irbNo ]);

  const getStudy = async () => {
    setLoading(true);
    const response = await sendGet(`${API.GET_STUDY_DETAIL}/${irbNo}`);
    setLoading(false);

    if (response?.data) {
      const data: IStudy = response.data;
      if (data && data.designList?.length > 0) {
        data.designList = data.designList.filter(design => !!design.designId);
      }
      setStudy(data);
    } else {
      setStudy(null);
    }
  };

  return (
    <>
      <TitleCard IconElement={IconDeviceAnalytics} title="임상 데이터" historyBackUrl="/monitoring/study" />
      {loading && 
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading && study &&
        <Grid container spacing={3} mt={1}>
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <StudyInfoCard study={study} className={'study-card'} />
          </Grid>
          <Grid item sm={12} md={12} lg={6} xl={6} >
          <StudySchedule study={study} onUpdated={setStudy} onRefresh={getStudy} />
          </Grid>
          <Grid item sm={12} md={12} lg={12} xl={12} className="design-card">
            <Grid container spacing={3}>
              <Grid item sm={12} md={6} lg={3} xl={3}>
                <SubCard title={getCaptionText(intl, 'design-list')}>
                  <DesignList designList={study.designList} onSelected={setCurDesign} /> 
                </SubCard>
              </Grid>
              {!!curDesign && 
                <Grid item sm={12} md={6} lg={9} xl={9}>
                  <SubCard title={getCaptionText(intl, 'target-list')}>
                    <Box display={'flex'}>
                      <FormattedMessage id="design-id" /> :
                      <Typography variant="h4" ml={1}>
                        {curDesign.designId}
                      </Typography>
                      <Box ml={3}>
                        <FormattedMessage id="design-name" /> :
                      </Box>
                      <Typography variant="h4" ml={1}>
                        {curDesign.name}
                      </Typography>
                    </Box>
                    <DesignSubjectList design={curDesign} /> 
                  </SubCard>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      }
    </>
)};

export default StudyDetailView;
