import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { IStudy } from 'types/monitoring/ctms';
import { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { sendGet } from 'utils/guard-axios';
import { API } from 'constant/api';
import TitleCard from 'ui-component/cards/TitleCard';
import { IconDeviceAnalytics } from '@tabler/icons';
import { CircularProgress, Grid, Typography } from '@mui/material';
import StudyInfoCard from 'ui-component/monitor/study/StudyInfoCard';
import SubCard from 'ui-component/cards/SubCard';
import { FormattedMessage } from 'react-intl';
import { grey } from '@mui/material/colors';

const StudyListView = () => {
  const [loading, setLoading] = useState<boolean> (false);
  const [dataSource, setDataSource] = useState<IStudy[]> ([]);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !user) return;
    getStudies();
  }, [ isLoggedIn, user ]);

  const getStudies = async () => {
    setLoading(true);
    const response = await sendGet(`${API.GET_STUDY_LIST}?piId=${user?.ctmsId}`);
    setLoading(false);
    if (response?.data) {
      setDataSource(response.data);
    } else {
      setDataSource([]);
    }
  };


  const routeToStudyUrl = (study: IStudy) => {
    navigate(`/monitoring/study/${study.irbNo}`);
  }

  return (
    <>
      <TitleCard IconElement={IconDeviceAnalytics} title="임상 데이터" />
      {loading && 
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading && 
        <Grid container spacing={3} mt={1}>
          {dataSource.length == 0 &&
            <Grid item sm={12}>
              <SubCard>
                <Typography color={grey[700]} display={'flex'} justifyContent={'center'}>
                  <FormattedMessage id="not-exist-data" />
                </Typography>
              </SubCard>
            </Grid>
          }
          {dataSource.map((data: IStudy, idx: number) => {
            return (
              <Grid key={idx} item sm={12} lg={6} xl={4}>
                <StudyInfoCard study={data} onClick={routeToStudyUrl} className={'study-card shadow-card'}/>
              </Grid>
            )
          })}
        </Grid>
      }
    </>
)};

export default StudyListView;
