import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { IError } from "types/admin/error";
import CurError from "ui-component/admin/CurError";
import MainCard from "ui-component/cards/MainCard";
import TitleCard from "ui-component/cards/TitleCard";
import { IconBug } from "@tabler/icons";
import moment from "moment";
import { useIntl } from "react-intl";
import { sendGet } from "utils/guard-axios";
import { API } from "constant/api";
import DateRange from "ui-component/DateRange";
import DataGrid, { Column, FilterRow, Scrolling, Selection } from "devextreme-react/data-grid";
import { getCaptionText } from "utils/inil";

const ErrorLogView = () => {
  const [curError, setCurError] = useState<IError | null> (null);
  const [fromDt, setFromDt] = useState<Date | null>(moment().subtract('30', 'd').toDate());
  const [toDt, setToDt] = useState<Date | null>(moment().toDate());
  const [dataSource, setDataSource] = useState<IError[]> ([]);
  const [loading, setLoading] = useState<boolean> (false);
  const intl = useIntl();

  const getErrors = async () => {
    setLoading(true);
    const response = await sendGet(`${API.GET_ERROR_LIST}?fromDt=${moment(fromDt).format('YYYY-MM-DD')}&toDt=${moment(toDt).format('YYYY-MM-DD')}`);
    setLoading(false);

    if (response?.data) {
      setDataSource(response.data);
    } else {
      setDataSource([])
    }
  };

  useEffect(() => {
    getErrors();
  }, []);

  return (
    <Box>
      <TitleCard title="에러 로그" IconElement={IconBug} />
      {loading && 
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading && 
        <Grid container spacing={3} mt={1}>
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <MainCard>
              <DateRange size={'small'} fromDt={fromDt} toDt={toDt} onChangeFromDt={setFromDt} onChangeToDt={setToDt} onRefresh={getErrors} xs={12} xl={8} />

              <Box mt={3}>
                <DataGrid 
                  dataSource={dataSource} 
                  height={960} 
                  showBorders={true} 
                  showRowLines={true} 
                  onRowClick={(event: any) => setCurError(event.data)}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}>
                  <Selection mode="single" />
                  <FilterRow visible={true} />
                  <Column allowFiltering={true} width={150} dataField="errDtm" caption={getCaptionText(intl, 'err-time')}/>
                  <Column allowFiltering={true} width={50} dataField="scheduleId" caption={getCaptionText(intl, 'schedule-id')} />
                  <Column allowFiltering={true} width={50} dataField="scheduleDtlId" caption={getCaptionText(intl, 'schedule-detail-id')} />
                  <Column allowFiltering={true} minWidth={250} dataField="objNm" caption={getCaptionText(intl, 'err-object')} />
                  <Scrolling mode="virtual" />
                </DataGrid>
              </Box>
            </MainCard>
          </Grid>
          { curError && 
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <CurError error={curError} />
            </Grid>
          } 
        </Grid>
      }
    </Box>
  )
};

export default ErrorLogView;
