import { CircularProgress, Slide, SlideProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IDiagnosticReport, IExam, IObservation, IPatient, URN_DEPT_ID_SYSTEM, URN_OBSERVATION_ID_SYSTEM, URN_PERFORMER_ID_SYSTEM, URN_REPORT_ID_SYSTEM } from "types/monitoring/cdw";
import { IProcdureCondition, IProcedure, ISubject } from "types/monitoring/ctms";
import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
  GroupPanel
} from 'devextreme-react/data-grid';
import { grey } from "@mui/material/colors";
import { getCaptionText } from "utils/inil";
import { API } from "constant/api";
import { sendGet } from "utils/guard-axios";


//TODO: 후에 제거
const CONDITION_PROCEDURE_LIST: IProcdureCondition[] = [
  { id: 'lab', procedureNames: [ '임상실험실 검사', '임상 실험실 검사', '임상실험실검사' ], examNames: [] },
  { id: 'ecg', procedureNames: [ '심전도 (12-lead ECG)', '심전도 검사' ], examNames: ['ECG(CTC)']},
];
const ERROR_RANGE = 24; //오차 범위
const PAGING_COUNT = 1000;
const UNMATCHED_MSG = 'UNMATCHED';
const SCREENING_MSG = 'SCREENING';

const CdwExamInfo = ({ subject }: { subject: ISubject }) => {
  const [loading, setLoading] = useState<boolean> (false);
  const [examList, setExamList] = useState<IExam[]> ([]);
  const intl = useIntl();

  const getDiagnosticReportAndProcedureData = async () => {
    try {
      setLoading(true);

      
      const response = await Promise.all([getDiagnosticReport(), getProcedureData()]);
      const examListRes: IExam[] = response[0]
        .sort((a: IExam, b: IExam) => moment(a.implDtm).diff(moment(b.implDtm)));
      const procedureListRes: IProcedure[] = response[1]?.data;

      
      if (!examListRes || !procedureListRes) return;

      // console.log("####### ", procedureListRes);
      
      const limitedProcedureList = getLimitedProcedureList(procedureListRes);
      
      const tmpExamList: IExam[] = [];
      const idxMap: Map<number, boolean> = new Map<number, boolean> ();

      for(let examData of examListRes) {
        //검사시행일과 프로시저 계산일의 차이가 오차 범위에 있는지 계산
        let idx = limitedProcedureList.findIndex(p => 
          Math.abs(getDurationHour(p.expectedDate, examData.implDtm)) <= ERROR_RANGE
            && p.condition?.examNames?.includes(examData.exmNm || ''));

        if (idx === -1) {
          idx = limitedProcedureList.findIndex(p => 
            Math.abs(getDurationHour(p.expectedDate, examData.implDtm)) <= ERROR_RANGE
            && p.condition?.examNames.length === 0
          );
        }

        if (idx > -1) {
          const procedure = limitedProcedureList[idx];
          examData.procedure = procedure;
          examData.ctmsExmNm = procedure.procedureName;
          idxMap.set(idx, true);
        } else if (moment(examData.implDt).diff(moment(subject.minDate)) < 0) {
          examData.ctmsExmNm = SCREENING_MSG;
        } else {
          examData.ctmsExmNm = UNMATCHED_MSG;
        }       

        tmpExamList.push(examData);
      }


      let idx = -1;
      for(let procedure of limitedProcedureList) {
        if (idxMap.get(++idx)) continue;
        tmpExamList.push({
          procedure: procedure,
          ctmsExmNm: procedure.procedureName
        });
      }


      setExamList(tmpExamList);
    } catch(err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getLimitedProcedureList = (procedureList: IProcedure[]): IProcedure[] => {
    return procedureList
      .filter((p: IProcedure) => {
        let isContained = false;
        for(let conditionProcedure of CONDITION_PROCEDURE_LIST) {
          if (conditionProcedure.procedureNames.includes(p.procedureName)) {
            p.condition = conditionProcedure;
            isContained = true;
            break;
          }
        }
        return isContained;
      })
      .map((p: IProcedure) => {
        p.timepoint = p.timepoint.includes('d') ? p.timepoint : p.timepoint + 'd';
        p.seq = convertTimepointIntoSeq(p.timepoint);
        p.expectedDate = moment(p.minDate).add(p.seq, 'hours').format('YYYY-MM-DD');
        return p;
      })
      .sort((a: IProcedure, b: IProcedure) => (a?.seq || 0) - (b?.seq || 0));
  }

  const getDiagnosticReport: () => Promise<IExam[]> = async () => {
    let hasNext = true;
    let url = `/fhir/DiagnosticReport?category=Exam&identifier=${encodeURIComponent(`urn:patient_id|${subject.ptNo}`)}&_count=${PAGING_COUNT}`;
    const list: IExam[] = [];

    while (hasNext) {
      const res = await sendGet(url);
      const diagnosticReportList: IDiagnosticReport[] = res?.data?.entry?.map((e: any) => e.resource);

      if (!diagnosticReportList || diagnosticReportList.length === 0) return list;

      for (let report of diagnosticReportList) {
        for (let observation of report.contained) {
          if (!observation.code.text) continue;
          const exam: IExam = convertObservationIntoExam(report, observation);
          list.push(exam);
        }
      }

      if (res?.data.link[1]?.relation === 'next') {
        const nextUrl = res.data.link[1].url;
        url = nextUrl.replace(new URL(nextUrl).origin, '');
      } else {
        hasNext = false;
      }
    }

    return list;
  };

  const getProcedureData = async () => {
    return await sendGet(`${API.GET_PROCEDURE_LIST}?ptNo=${subject.ptNo}`);
  };

  const convertObservationIntoExam = (report: IDiagnosticReport, observation: IObservation): IExam => {
    return {
      id: report.id,
      reportId: report.identifier.find((i: any) => i.system === URN_REPORT_ID_SYSTEM)?.value,
      reportDtm: report.effectiveDateTime,
      recordId: observation.identifier.find((i: any) => i.system === URN_OBSERVATION_ID_SYSTEM)?.value,
      implDtm: moment(observation.effectiveDateTime).format('YYYY-MM-DD HH:mm:ss'),
      implDt: moment(observation.effectiveDateTime).format('YYYY-MM-DD'),
      exmCd: observation.code.coding[0].code,
      exmNm: observation.code.coding[0].display,
      ordSlipCtgCd: observation.code.coding[1].code,
      ordSlipCtgNm: observation.code.coding[1].display,
      mdfmCpemId: observation.code.coding[2].code,
      mdfmCpemNm: observation.code.coding[2].display,
      value: observation.code.text,
      performerId: observation.performer.find((p: any) => p.identifier.system === URN_PERFORMER_ID_SYSTEM)?.identifier.value,
      performerNm: observation.performer.find((p: any) => p.identifier.system === URN_PERFORMER_ID_SYSTEM)?.display,
      performerDeptId: observation.performer.find((p: any) => p.identifier.system === URN_DEPT_ID_SYSTEM)?.identifier.value,
      performerDeptNm: observation.performer.find((p: any) => p.identifier.system === URN_DEPT_ID_SYSTEM)?.display,
      status: observation.status
    };
  }

  const getDurationHour = (target?: string, source?: string) => {
    return moment.duration(moment(target).diff(source)).asHours();
  }

  const convertTimepointIntoSeq = (timepoint: string): number => {
    let dayNum = Number(timepoint.slice(0, timepoint.indexOf('d'))) * 24;
    let hourNum = Number(timepoint.slice(timepoint.indexOf('d') + 1).replace('h', ''));

    return dayNum + hourNum;
  };

  

  const examValueRender = (event: any) => {
    const isFile = event.data.value?.startsWith(API.GET_FILE_RESOURCE);
    
    return (
      <Box>
        { isFile ? 
          <a href={event.data.value} target='_blank'>
            <FormattedMessage id="image" />
          </a>
          : 
          <>
            {event.data.value}
          </>
        }
      </Box>
    )
  };

  const getDisplayTimepoint= (data: IExam) => {
    if (!data?.procedure) {
      if (moment(data.implDt).diff(subject.minDate) < 0) return SCREENING_MSG;
      return UNMATCHED_MSG;
    } 
    return `${data.procedure.timepoint}  /  예정일: ${data.procedure.expectedDate}`;
  };

  const getSortingMethod = (target: string, source: string) => {
    if (target === SCREENING_MSG || source === SCREENING_MSG) {
      return target === SCREENING_MSG ? -1 : 1;
    } else if (target === UNMATCHED_MSG || source === UNMATCHED_MSG) {
      return target === UNMATCHED_MSG ? 1 : -1;
    } else {
      // console.log("######## ", target, source);
      const targetTimepoint = target.substring(0, target.indexOf('/')).trim();
      const sourceTimepoint = source.substring(0, source.indexOf('/')).trim();

      return convertTimepointIntoSeq(targetTimepoint) - convertTimepointIntoSeq(sourceTimepoint);
    }
  };

  useEffect(() => {
    getDiagnosticReportAndProcedureData();
  }, [ subject ]);

  return (
    <Box>
       {loading &&
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading &&
        <Box>
          <Typography variant="h5">총 건수 : {examList.length} 건</Typography>
          {examList.length > 0 &&
            <Box mt={2}>
              <DataGrid dataSource={examList}
                height={900}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}>
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <Column 
                  allowSorting={false}
                  dataField="procedure" 
                  caption="Timepoint" 
                  groupIndex={0} 
                  sortingMethod={getSortingMethod}
                  calculateGroupValue={getDisplayTimepoint} />
                <Column allowFiltering={true} dataField="ctmsExmNm" caption="CTMS 검사명" groupIndex={2} allowSorting={false} />
                <Column allowFiltering={true} width={180} dataField="implDtm" caption={getCaptionText(intl, 'impl-dtm')} />
                <Column allowFiltering={true} width={150} dataField="ordSlipCtgNm" caption={getCaptionText(intl, 'ord-slip-ctg-nm')} />
                <Column allowFiltering={true} width={100} dataField="exmCd" caption={getCaptionText(intl, 'exam-code')} />
                <Column allowFiltering={true} width={150} dataField="exmNm" caption={getCaptionText(intl, 'exam-name')} />
                <Column allowFiltering={true} width={300} dataField="mdfmCpemNm" caption={getCaptionText(intl, 'mdfm-cpem-nm')} />
                <Column allowFiltering={true} minWidth={300} dataField="value" caption={getCaptionText(intl, 'exam-value')} cellRender={examValueRender} />
                <Scrolling mode="virtual" />
              </DataGrid>
            </Box>
          }
          {examList.length === 0 &&
            <Typography color={grey[700]} display={'flex'} justifyContent={'center'}>
              <FormattedMessage id="not-exist-data" />
            </Typography>
          }
        </Box>
      }

    </Box>
  )
};


export default CdwExamInfo;
