import { Grid, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IconRefresh } from '@tabler/icons';
import { ko } from 'date-fns/locale';

interface IDateRange {
  size: 'small' | 'medium';
  fromDt: Date | null;
  toDt: Date | null;
  onChangeFromDt: Function;
  onChangeToDt: Function;
  onRefresh: any;
  xs: number;
  xl: number;
}

const DateRange = ({ size, fromDt, toDt, onChangeFromDt, onChangeToDt, onRefresh, xs, xl }: IDateRange) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12 - xs} xl={12 - xl} />
        <Grid item xs={xs} xl={xl} display='flex' justifyContent={'right'}>
          <DatePicker
            value={fromDt}
            onChange={(newValue) => onChangeFromDt(newValue)}
            renderInput={(params) => <TextField {...params} size={size} />}
            inputFormat="yyyy-MM-dd" />
          <Box display={'flex'} alignItems={'center'} ml={2} mr={2}>~</Box>
          <DatePicker
            value={toDt}
            onChange={(newValue) => onChangeToDt(newValue)}
            renderInput={(params) => <TextField {...params} size={size} />}
            inputFormat="yyyy-MM-dd" />
          <IconButton aria-label="refresh" onClick={onRefresh} className={'ml10'}>
            <IconRefresh />
          </IconButton>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
};

export default DateRange;