// action - state management
import { LOGIN, LOGOUT, REGISTER } from '../actions';
import { InitialLoginContextProps } from 'types/auth';
import { createSlice } from '@reduxjs/toolkit';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
    type: string;
    payload?: InitialLoginContextProps;
}

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isSso: false,
    isInitialized: false,
    user: null
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signIn(state, action) {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.isInitialized = true;
            state.isSso = action.payload.isSso;
        },
        signOut(state) {
            state.isInitialized = true;
            state.isLoggedIn = false;
            state.user = null;
        }
    }

});


export default user.reducer;

export const { signIn, signOut } = user.actions;
