import { CircularProgress, Slide, SlideProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IDiagnosticReport, IExam, IForm, IObservation, IPatient, URN_DEPT_ID_SYSTEM, URN_OBSERVATION_ID_SYSTEM, URN_PERFORMER_ID_SYSTEM, URN_REPORT_ID_SYSTEM } from "types/monitoring/cdw";
import { ISubject } from "types/monitoring/ctms";
import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
  GroupPanel
} from 'devextreme-react/data-grid';
import { grey } from "@mui/material/colors";
import { getCaptionText } from "utils/inil";
import { sendGet } from "utils/guard-axios";



const CdwFormInfo = ({ subject }: { subject: ISubject }) => {
  const [loading, setLoading] = useState<boolean> (false);
  const intl = useIntl();
  const [formList, setFormList] = useState<IForm[]> ([]);

  const getDiagnosticReport = async () => {
    try {
      setLoading(true);
      let hasNext = true;
      let url = `/fhir/DiagnosticReport?category=Form&identifier=${encodeURIComponent(`urn:patient_id|${subject.ptNo}`)}&_count=1000`;
      const list: IForm[] = [];

      while (hasNext) {
        const res = await sendGet(url);
        const diagnosticReportList: IDiagnosticReport[] = res?.data?.entry?.map((e: any) => e.resource);

        if (!diagnosticReportList || diagnosticReportList.length === 0) return;

        for (let report of diagnosticReportList) {
          for (let observation of report.contained) {
            if (!observation.code.text) continue;
            const form: IForm = convertObservationIntoForm(report, observation);
            list.push(form);
          }
        }

        if (res?.data.link[1]?.relation === 'next') {
          const nextUrl = res.data.link[1].url;
          url = nextUrl.replace(new URL(nextUrl).origin, '');
        } else {
          hasNext = false;
        }
      }

      setFormList(list);
    } catch(err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const convertObservationIntoForm = (report: IDiagnosticReport, observation: IObservation): IForm => {
    return {
      id: report.id,
      reportId: report.identifier.find((i: any) => i.system === URN_REPORT_ID_SYSTEM)?.value,
      reportDtm: report.effectiveDateTime,
      recordId: observation.identifier.find((i: any) => i.system === URN_OBSERVATION_ID_SYSTEM)?.value,
      recDtm: moment(observation.effectiveDateTime).format('YYYY-MM-DD HH:mm:ss'),
      recDt: moment(observation.effectiveDateTime).format('YYYY-MM-DD'),
      mdfmId: observation.code.coding[0].code,
      mdfmNm: observation.code.coding[0].display,
      mdfmClsCd: observation.code.coding[1].code,
      mdfmClsNm: observation.code.coding[1].display,
      mdfmCpemId: observation.code.coding[2].code,
      mdfmCpemNm: observation.code.coding[2].display,
      value: observation.code.text,
      writerId: observation.performer.find((p: any) => p.identifier.system === URN_PERFORMER_ID_SYSTEM)?.identifier.value,
      writerNm: observation.performer.find((p: any) => p.identifier.system === URN_PERFORMER_ID_SYSTEM)?.display,
      writerDeptId: observation.performer.find((p: any) => p.identifier.system === URN_DEPT_ID_SYSTEM)?.identifier.value,
      writerDeptNm: observation.performer.find((p: any) => p.identifier.system === URN_DEPT_ID_SYSTEM)?.display,
      status: observation.status
    };
  }

  const getFormValue = (event: any) => {
    return (
      <Box>
       {event.data.value?.startsWith('/api/file') && 
          <a href={event.data.value} target='_blank'>
            <FormattedMessage id="image" />
          </a>
        }
        {!event.data.value?.startsWith('/api/file') && 
          <>
            {event.data.value}
          </>
        }
      </Box>
    )
  };

  useEffect(() => {
    getDiagnosticReport();
  }, [ subject ]);

  return (
    <Box>
       {loading &&
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading &&
        <Box>
          <Typography variant="h5">총 건수 : {formList.length} 건</Typography>
          {formList.length > 0 &&
            <Box mt={2}>
              <DataGrid dataSource={formList}
                height={900}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}>
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                {/* <Column allowFiltering={true} width={150} dataField="recDt" caption={getCaptionText(intl, 'impl-dtm')} groupIndex={1} /> */}
                <Column allowFiltering={true} width={180} dataField="recDtm" caption={getCaptionText(intl, 'rec-dtm')} groupIndex={1} />
                <Column allowFiltering={true} width={150} dataField="mdfmClsNm" caption={getCaptionText(intl, 'mdfm-cls-nm')} groupIndex={2} />
                <Column allowFiltering={true} width={100} dataField="mdfmId" caption={getCaptionText(intl, 'form-id')} />
                <Column allowFiltering={true} width={150} dataField="mdfmNm" caption={getCaptionText(intl, 'form-name')} />
                <Column allowFiltering={true} width={300} dataField="mdfmCpemNm" caption={getCaptionText(intl, 'mdfm-cpem-nm')} />
                <Column allowFiltering={true} minWidth={300} dataField="value" caption={getCaptionText(intl, 'form-value')} cellRender={getFormValue} />
                <Column allowFiltering={true} width={150} dataField="writerDeptId" caption={getCaptionText(intl, 'writer-dept-id')} />
                <Column allowFiltering={true} width={150} dataField="writerDeptNm" caption={getCaptionText(intl, 'writer-dept-nm')} />
                <Column allowFiltering={true} width={100} dataField="writerId" caption={getCaptionText(intl, 'writer-id')} />
                <Column allowFiltering={true} width={100} dataField="writerNm" caption={getCaptionText(intl, 'writer-nm')} />
                <Column allowFiltering={true} width={150} dataField="status" caption={getCaptionText(intl, 'status')} />
                <Scrolling mode="virtual" />
              </DataGrid>
            </Box>
          }
          {formList.length === 0 &&
            <Typography color={grey[700]} display={'flex'} justifyContent={'center'}>
              <FormattedMessage id="not-exist-data" />
            </Typography>
          }
        </Box>
      }

    </Box>
  )
};


export default CdwFormInfo;
