import { repeatPeriodTextList, weekTextList } from "types/admin/schedule";

export const convertRuleIntoText= (rule: string): string => {
  const arr = rule.split(' ');
  let periodText = '';

  if (arr[3] != '*') {
    periodText = `${repeatPeriodTextList[0].text} ${arr[3]}일 `;
  } else if (arr[5] !== '*') {
    periodText = `${repeatPeriodTextList[1].text} ${weekTextList[Number(arr[5])].text}요일 `;
  } else {
    periodText = `${repeatPeriodTextList[2].text} `;
  }

  periodText += `${arr[2]}시`;

  if (Number(arr[1]) > 0) {
    periodText += ` ${arr[1]}분`;
  }

  return `${periodText}에 적재`;
};