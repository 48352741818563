import { Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { IconCircleCheck, IconCircleMinus } from "@tabler/icons";

const ScheduleActiveBox = ({ isActive }: { isActive: boolean }) => {
  return (
    <Box>
      {isActive && 
        <Box ml={1} display={'flex'} alignItems={'center'}>
          <IconCircleCheck color={green['A700']} size={20} />
          <Typography variant='h5' ml={'3px'}>
            ON
          </Typography>
        </Box>
      }
      {!isActive && 
        <Box ml={1} display={'flex'} alignItems={'center'}>
          <IconCircleMinus color={red[800]} size={20}/>
          <Typography variant='h5'  ml={'3px'}>
            OFF
          </Typography>
        </Box>
      }
    </Box>
  )
};

export default ScheduleActiveBox;