export interface IScheduleLog {
  scheduleId: number;
  scheduleDetailId: number;
  jobExecutionId: number;
  stepExecutionId: number;
  irbNo: string;
  stepName: string;
  stepNameText: string;
  startTime: string;
  endTime: string;
  runningTime: string;
  status: string;
  msg: string;
  loadDtm: string;
};

export interface ISchedule {
  id: number;
  rule: string;
  name: string;
  isActive: boolean;
}

export interface IScheduleDetail {
  id: number;
  scheduleId: number;
  irbNo: string;
  seq: number;
  title?: string;
  type?: string;
  lastStatus: string;
  isActive: boolean;
  isRunning: boolean;
}

export interface IScheduleConst {
  id: number;
  text: string;
}

export const repeatPeriodTextList: IScheduleConst[] = [
  { id: 1, text: '매월' },
  { id: 2, text: '매주' },
  { id: 3, text: '매일' },
];

export const weekTextList: IScheduleConst[] = [
  { id: 0, text: '일' },
  { id: 1, text: '월' },
  { id: 2, text: '화' },
  { id: 3, text: '수' },
  { id: 4, text: '목' },
  { id: 5, text: '금' },
  { id: 6, text: '토' },

];