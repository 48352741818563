const API = {
  GET_SCHEDULE_LIST: '/api/schedule/',
  GET_SCHEDULE_LOG: '/api/schedule/log',
  GET_SCHEDULE_DETAIL: '/api/schedule/detail',
  RUN_SCHEDULE_DETAIL: '/api/schedule/detail/run',
  CANCEL_SCHEDULE_DETAIL: '/api/schedule/detail/cancel',
  GET_ERROR_LIST: '/api/error/',
  GET_ERROR_DETAIL_LIST: '/api/error/detail',
  GET_STUDY_LIST: '/api/monitor/study',
  GET_STUDY_DETAIL: '/api/monitor/study',
  UPDATE_STUDY_SCHEDULE: '/api/monitor/study/schedule',
  GET_SUBJECT_LIST: '/api/monitor/subject',
  GET_LOGIN_USER: '/api/security/login-user',
  SIGN_IN: '/api/security/sign-in',
  SIGN_OUT: '/api/security/sign-out',
  SSO: '/api/security/sso',
  SSE: '/api/schedule/sse/subscribe',
  SSE_LAST_DATA: '/api/schedule/sse/last-data',
  GET_PROCEDURE_LIST: '/api/monitor/procedure',
  CREATE_SCHEDULE: '/api/schedule',
  UPDATE_SCHEDULE: '/api/schedule',
  DELETE_SCHEDULE: '/api/schedule',
  GET_FILE_RESOURCE: '/api/file'
};

export { API };