import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IPatient, URN_PATIENT_ID_SYSTEM } from "types/monitoring/cdw";
import { ISubject } from "types/monitoring/ctms";
import { sendGet } from "utils/guard-axios";
import { errorToast } from "utils/toast";

const CdwPatientInfo = ({ subject }: { subject: ISubject}) => {
  const [patientInfo, setPatientInfo] = useState<IPatient | null> (null);

  const getPatientId = (patientInfo: IPatient) => {
    return patientInfo.identifier.find(i => i.system === URN_PATIENT_ID_SYSTEM)?.value;    
  };

  const getPatient = async () => {
    const res = await sendGet(`/fhir/Patient?identifier=${encodeURIComponent(`urn:patient_id|${subject.ptNo}`)}`);
    if (!res || res.data.total === 0) return;
    setPatientInfo(res.data.entry[0].resource);
  };
    
  useEffect(() => {
    getPatient();
  }, [ subject ]);

  return (
    <Box>
       {!patientInfo &&
        <Typography color={grey[700]} display={'flex'} justifyContent={'center'}>
          <FormattedMessage id="not-exist-data" />
        </Typography>
      }
      {!!patientInfo && 
        <Grid container>
          <Grid item sm={6} lg={3} xl={3} display={'flex'} mt={2}>
            <Typography variant="h5">
              <FormattedMessage id="pt-no" />:
            </Typography>
            <Typography variant="h5" ml={2}>
              {getPatientId(patientInfo)}
            </Typography>
          </Grid>
          <Grid item sm={6} lg={3} xl={3} display={'flex'} mt={2}>
            <Typography variant="h5">
              <FormattedMessage id="pt-nm" />:
            </Typography>
            <Typography variant="h5" ml={2}>
              {patientInfo.name[0].family}
            </Typography>
          </Grid>
          <Grid item sm={6} lg={3} xl={3} display={'flex'} mt={2}>
            <Typography variant="h5">
              <FormattedMessage id="gender" />:
            </Typography>
            <Typography variant="h5" ml={2}>
              {patientInfo.gender}
            </Typography>
          </Grid>
          <Grid item sm={6} lg={3} xl={3} display={'flex'} mt={2}>
            <Typography variant="h5">
              <FormattedMessage id="birth-date" />:
            </Typography>
            <Typography variant="h5" ml={2}>
              {patientInfo.birthDate}
            </Typography>
          </Grid>
          <Grid item sm={6} lg={3} xl={3} display={'flex'} mt={2}>
            <Typography variant="h5">
              <FormattedMessage id="study-start-date" />:
            </Typography>
            <Typography variant="h5" ml={2}>
              {subject.minDate}
            </Typography>
          </Grid>
          <Grid item sm={6} lg={3} xl={3} display={'flex'} mt={2}>
            <Typography variant="h5">
              <FormattedMessage id="study-end-date" />:
            </Typography>
            <Typography variant="h5" ml={2}>
              {subject.maxDate}
            </Typography>
          </Grid>
        </Grid>
      }
    </Box>
  )
};

export default CdwPatientInfo;