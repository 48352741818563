// material-ui
// project imports
import MainCard from 'ui-component/cards/MainCard';
import DataGrid, {
    Scrolling,
    Column,
    FilterRow,
    GroupPanel
} from 'devextreme-react/data-grid';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import axios from 'utils/axios';
import { IScheduleLog } from 'types/admin/schedule';
import { getCaptionText } from 'utils/inil';
import { useIntl } from 'react-intl';
import { errorToast } from 'utils/toast';
import { IconRefresh, IconTable } from '@tabler/icons';
import DateRange from 'ui-component/DateRange';
import { API } from 'constant/api';
import { useNavigate } from 'react-router-dom';
import { sendGet } from 'utils/guard-axios';
import TitleCard from 'ui-component/cards/TitleCard';

const ScheduleLogView = () => {
  const [loading, setLoading] = useState<boolean> (false);
  const [fromDt, setFromDt] = useState<Date | null>(moment().subtract(1, 'd').toDate());
  const [toDt, setToDt] = useState<Date | null>(moment().toDate());
  const [dataSource, setDataSource] = useState<IScheduleLog[]> ([]);
  const intl = useIntl();

  useEffect(() => {
    getScheduleLogs();
  }, [fromDt, toDt]);

  const getScheduleLogs = async () => {
    setLoading(true);
    const response = await sendGet(`${API.GET_SCHEDULE_LOG}?fromDt=${moment(fromDt).format('YYYY-MM-DD')}&toDt=${moment(toDt).format('YYYY-MM-DD')}`);
    setLoading(false);
    if (response && response.data) {
      setDataSource(response.data);
    } else {
      setDataSource([])
    }
  }

  return (
    <>
     <TitleCard IconElement={IconTable} title="스케쥴 로그" />
      {loading && 
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <CircularProgress />
        </Box>
      }
      {!loading && 
        <Grid container spacing={3} mt={1}>
          <Grid item sm={12} md={12} lg={12} xl={12}>
            <MainCard>
              <DateRange size={'small'} fromDt={fromDt} toDt={toDt} onChangeFromDt={setFromDt} onChangeToDt={setToDt} onRefresh={getScheduleLogs} xs={7} xl={4} />

              <Box mt={1}>
                <DataGrid dataSource={dataSource} 
                  height={900} 
                  showBorders={true} 
                  showRowLines={true}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}>
                    <GroupPanel visible={true} />
                    <FilterRow visible={true} />
                    <Column allowFiltering={true} width={50} dataField="scheduleId" caption={getCaptionText(intl, 'schedule-id')}/>
                    <Column allowFiltering={true} width={50} dataField="scheduleDetailId" caption={getCaptionText(intl, 'schedule-detail-id')} />
                    <Column allowFiltering={true} width={50} dataField="jobExecutionId" caption={getCaptionText(intl, 'job-execution-id')} />
                    <Column allowFiltering={true} width={50} dataField="stepExecutionId" caption={getCaptionText(intl, 'step-execution-id')}  />
                    <Column allowFiltering={true} width={150} dataField="irbNo" caption={getCaptionText(intl, 'irb-no')} groupIndex={0} />
                    <Column allowFiltering={true} width={200} dataField="stepNameText" caption={getCaptionText(intl, 'step-name')}  />
                    <Column allowFiltering={true} width={150} dataField="startTime" caption={getCaptionText(intl, 'start-time')} />
                    <Column allowFiltering={true} width={150} dataField="endTime" caption={getCaptionText(intl, 'end-time')} />
                    <Column allowFiltering={true} width={150} dataField="runningTime" caption={getCaptionText(intl, 'running-time')} />
                    <Column allowFiltering={true} width={150} dataField="status" caption={getCaptionText(intl, 'status')} />
                    <Column allowFiltering={true} minWidth={250} dataField="msg" caption={getCaptionText(intl, 'msg')} />
                    <Column allowFiltering={true} width={150} dataField="loadDtm" caption={getCaptionText(intl, 'load-time')} />
                    <Scrolling mode="virtual" />
                </DataGrid>
              </Box>
            </MainCard>
          </Grid>
        </Grid>
      }
    </>
)};

export default ScheduleLogView;
