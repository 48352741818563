import { IProcedure } from "./ctms";

export const URN_DESIGN_ID_SYSTEM = "urn:design_id";
export const URN_PATIENT_ID_SYSTEM = "urn:patient_id";
export const URN_REPORT_ID_SYSTEM = "urn:report_id";
export const URN_PERFORMER_ID_SYSTEM = "urn:performer_id";
export const URN_DEPT_ID_SYSTEM = "urn:performer_dept_id";
export const URN_OBSERVATION_ID_SYSTEM = "urn:observation_id";

export interface IIdentifier {
  system: string;
  value: string;
}

export interface IName {
  family: string;
}

export interface ICode {
  coding: ICoding[];
  text: string;
}

export interface ICoding {
  code: string;
  display?: string;
}

export interface IValueQuantity {
  value: number;
}

export interface IPerformer {
  display: string;
  identifier: IIdentifier;
}

export interface IObservation {
  id: string;
  identifier: IIdentifier[];
  resourceType: string;
  effectiveDateTime: string;
  code: ICode;
  status: string;
  valueQuantity?: IValueQuantity;
  performer: IPerformer[];
}

export interface IPatient {
  id: string;
  resourceType: string;
  birthDate: string;  
  gender: string;
  name: IName[];
  identifier: IIdentifier[];
}

export interface IDiagnosticReport {
  id: string;
  resourceType: string;
  category: ICoding[];
  effectiveDateTime: string;
  contained: IObservation[];
  identifier: IIdentifier[];
}

export interface IExam {
  id?: string;
  reportId?: string;
  reportDtm?: string;
  recordId?: string;
  implDt?: string;
  implDtm?: string;
  ordSlipCtgCd?: string;
  ordSlipCtgNm?: string;
  mdfmCpemId?: string;
  mdfmCpemNm?: string;
  exmCd?: string;
  exmNm?: string;
  value?: string;
  performerId?: string;
  performerNm?: string;
  performerDeptId?: string;
  performerDeptNm?: string;
  status?: string;
  procedure?: IProcedure;
  ctmsExmNm?: string;
}

export interface IForm {
  id: string;
  reportId?: string;
  recordId?: string;
  reportDtm?: string;
  mdfmCpemId?: string;
  mdfmCpemNm?: string;
  mdfmClsCd?: string;
  mdfmClsNm?: string;
  writerId?: string;
  writerNm?: string;
  writerDeptId?: string;
  writerDeptNm?: string;
  recDt?: string;
  recDtm?: string;
  status?: string;
  value?: string;
  mdfmId?: string;
  mdfmNm?: string;
}
