// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// type
import { NavItemType } from 'types';

const icons = {
    IconDeviceAnalytics: IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const monitor: NavItemType = {
    id: 'monitor',
    title: <FormattedMessage id="monitoring" />,
    icon: icons.IconDeviceAnalytics,
    type: 'group',
    children: [
      {
          id: 'monitoring',
          title: <FormattedMessage id="monitoring-data" />,
          type: 'item',
          url: '/monitoring/study',
          icon: icons.IconDeviceAnalytics,
          breadcrumbs: false
      }    
    ]
};

export default monitor;
